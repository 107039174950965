/* Copyright 2023 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com) */


.nav-buttons {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-buttons .pages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.nav-buttons .pages a {
    border-radius: 0;
    background-color: color-mix(in srgb, #337ab7 50%, black)
}

.nav-buttons .pages a.selected {
    background-color: #337ab7;
}


.nav-buttons .pages a:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nav-buttons .pages a:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.nav-buttons a {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    border-radius: 0.25rem;
    text-decoration: none;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.nav-buttons a.back {
    padding-right: 1.25rem;
}

.nav-buttons a.next {
    padding-left: 1.25rem;
}

.nav-buttons a.submit {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.nav-buttons a:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
    text-decoration: none;
}

.nav-buttons a:visted {
    color: #fff;
}

.nav-buttons a chevron-left {
    margin-right: 0.5rem;
}

.nav-buttons a chevron-right {
    margin-left: 0.5rem;
}


.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
}


.page .body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
}

.page .body .field {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.page .body .field textarea {
    height: 6rem;
    margin-bottom: 0.7rem;
    width: 100%;
    margin-left: 1rem;
}

.page .body .field select {
    margin-left: 1rem;
    margin-bottom: 0.7rem;
    height: 2rem;
    min-width: 10rem;
}

.page .body label {
    font-family: 'Rokkitt', serif;
}


.page .body .field .input {
    width: 100%;
    max-width: 20rem;
}

.page .body .field input {
    width: 100%;
    max-width: 20rem;
    margin-left: 1rem;
    margin-bottom: 0.7rem;
    height: 1.5rem;
    font-size: 1.2rem;
    font-family: 'Rokkitt', serif;
}


.validate input:invalid {
    border: 1px solid red;
    border-radius: 3px;
}

.validate input:invalid:focus {
    outline: none;
    box-shadow: 0 0 3px red;
}
